<template>
  <base-card class="ip-only-order">
    <v-card-text>
      <v-row v-if="!!orderInfo">
        <v-col cols="12" md="10" lg="8" offset-md="1" offset-lg="2">
          <div class="text-subtitle-1 font-weight-bold my-2 mt-2 mb-6 underline">
            {{ orderInfo.netadminServiceName }}
          </div>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('order.orderDate')"
                :value="orderInfo.customerOrderDate"
                disabled
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('order.desiredDeliveryDate')"
                :value="orderInfo.desiredDeliveryDate"
                disabled
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('address.address')"
                :value="orderInfo.fullAddress"
                disabled
              />
            </v-col>
          </v-row>

          <div class="text-subtitle-1 font-weight-bold my-2 mt-2 mb-6 underline">
            {{ $t("order.personalInformation") }}
          </div>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('price.customerName')"
                :value="orderInfo.customerName"
                disabled
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('common.phoneNumber')"
                :value="orderInfo.customerMobilePhone"
                disabled
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('common.email')"
                :value="orderInfo.customerEmail"
                disabled
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                label="SSN"
                :value="orderInfo.customerInformation && orderInfo.customerInformation.ssn"
                disabled
              />
            </v-col>
          </v-row>

          <div class="text-subtitle-1 font-weight-bold my-2 mt-2 mb-6 underline">
            {{ $t("order.providerServiceInformation") }}
          </div>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('price.startCost')"
                :value="`${orderInfo.serviceProviderServiceInformation.startFee} SEK`"
                disabled
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('order.monthlyCost')"
                :value="`${orderInfo.serviceProviderServiceInformation.monthlyFee} SEK`"
                disabled
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('order.noticePeriod')"
                :value="orderInfo.serviceProviderServiceInformation.noticePeriod"
                disabled
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('order.target')"
                :value="orderInfo.serviceProviderServiceInformation.target"
                disabled
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('order.serviceId')"
                :value="orderInfo.serviceProviderServiceInformation.eklundhsServiceId"
                disabled
              />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="pt-0"
                :label="$t('order.service')"
                :value="orderInfo.serviceProviderServiceInformation.eklundhsService"
                disabled
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    metaInfo: {
      title: 'IP-Only Web Order',
    },

    data () {
      return {
        orderId: '',
        orderInfo: null,
      }
    },

    mounted () {
      this.orderId = this.$route.params.orderId

      this.loadOrderInfo()
    },

    methods: {
      ...mapActions({
        fetchIpOnlyOrder: 'fetchIpOnlyOrder',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async loadOrderInfo() {
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)

        try {
          this.orderInfo = await this.fetchIpOnlyOrder(this.orderId)
          const unixOrderDate = Math.floor(this.orderInfo.customerOrderDate / 1000)
          const unixDeliveryDate = Math.floor(this.orderInfo.desiredDeliveryDate / 1000)

          this.orderInfo.customerOrderDate = util.formatDateTime(new Date(unixOrderDate * 1000))
          this.orderInfo.desiredDeliveryDate = util.formatDateTime(new Date(unixDeliveryDate * 1000))
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },
    }
  }
</script>

<style lang="scss">
  .ip-only-order {
    .underline {
      border-style: solid;
      border-width: 0 0 thin 0;
      border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
    }

    .theme--light.v-select .v-select__selection--disabled,
    .theme--light.v-input--is-disabled input {
      color: rgba(0, 0, 0, 0.87);
    }
  }
</style>
